import ChatAvatar from "./chat-avatar";
import Markdown from 'react-markdown';
import { useSearchParams } from "react-router-dom";
import {
  isImageLink,
} from "../../../libs";

export default function ChatItem(props) {
  const { message } = props;
  let [searchParams] = useSearchParams();
  let shopId = searchParams.get("shopId");

  const renderers = {
    a: (props) => {
      let { href, children } = props;
      if (isImageLink(href)) {
        return (
          <img src={href} className="list-image-none mb-3" />
        )
      } else {
        return (
          <a href={href} className="font-medium text-blue-600 dark:text-blue-500 hover:underline" target={shopId?"_parent":"_blank"} rel="noopener noreferrer">
            {children}
          </a>
        )
      }
    },
  };
 
  return (
    <div className={`flex items-start gap-2 pe-2 ${message.role === "user" ? "justify-end":"justify-start"}`}>
      <div className={`flex flex-col rounded p-3 ${message.role === "user" ? "order-0 bg-[#3047EC] text-white":"order-1 bg-[#f5f6f8]"}`}>
        <Markdown className="break-words" components={renderers}>{message.content}</Markdown>
        {message.data &&
          <img className="w-[100px] pt-3" src={`${message.data.image_url.url}`}></img>
        }
      </div>
      <ChatAvatar {...message} />
    </div>
  );
}
