import "../../tailwind.css";
import { useApp } from '../../../AppContext';
import { formatTime } from "../../../libs";
import { ReactComponent as ChatIcon } from '../../icons/chatbubbles.svg';
import { ReactComponent as ZaloIcon } from '../../icons/zalo.svg';
import { ReactComponent as WaIcon } from '../../icons/whatsapp.svg';
import { ReactComponent as MeIcon } from '../../icons/messenger.svg';
import { isMobile } from "react-device-detect";
const ChannelComponent = ({
  chatbot,
  HanldeGetDataMess,
  setFlow,
  setNewChat,
  setPrompt,
  messages,
}) => {
  const waNumber = chatbot?.whatsappNumber?.replace(/[+\s]/g, "")?.replace(/\s+/g, "");
  const { translations } = useApp();
  let message = messages?.length > 0 ? messages[0] : []
  return (
    <div className="w-full h-full bg-white  text-[#272727] py-3 px-4 rounded-[20px] flex items-center justify-between">
      <div className="w-1/2">
        <p className="font-semibold text-[16px]">{translations?.recent_messages}</p>
        <p className="text-[13px] flex items-center justify-between"><span>{message.name}</span> <span className="text-gray-400">{message?.updatedAt && formatTime(message?.updatedAt)}</span></p>
      </div>
      <div className="flex items-center gap-3">
        <div
          onClick={() => {
            HanldeGetDataMess();
            setFlow(undefined);
            setPrompt(undefined);
            setNewChat(true);
          }}
          className=""
        >
          <ChatIcon style={{ color: chatbot?.chatbotColor || "#3047EC" }} className="w-[24px] cursor-pointer" />
        </div>
        
        {chatbot?.whatsappNumber && (
          <a
            href={isMobile
              ? `https://api.whatsapp.com/send/?phone=${waNumber}&text&type=phone_number&app_absent=0`
              : `https://web.whatsapp.com/send?phone=${waNumber}&text=hi`}
            target="_blank"
          >
            <WaIcon className="w-[24px] cursor-pointer" />
          </a>
        )}

        {chatbot?.pageId && (
          <a
            href={`https://www.messenger.com/t/${chatbot.pageId}`}
            target="_blank"
          >
            <MeIcon className="w-[22px] cursor-pointer" />
          </a>
        )}

        {chatbot?.oaId && (
          <a
            href={`http://zalo.me/${chatbot?.oaId}`}
            target="_blank"
          >
            <ZaloIcon className="w-[24px] cursor-pointer" />
          </a>
        )}

      </div>
    </div>
  );
};
export default ChannelComponent;
