import { useState } from "react";
import axios from "axios";
import FormInput from "../element/Input";
import {
  validates,
  appURL,
} from "../../../libs";

export default function FormLead({
  uuid,
  botId,
  setcheckLead,
  setLead,
  nextstep,
}: any) {
  const [require, setRequire] = useState<boolean>(false)
  const [display, setDisplay] = useState<string>("name")
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const hanldeCreateLead = async () => {
    await axios.post(`${appURL}/api/lead`,
      {
        uuid,
        botId,
        name,
        email
      })
      .then((res) => {
        setLead(res.data)
        setcheckLead(false)
      })
      .catch((error) => {
        console.log(error);
      })

  }

  const hanldeClick = () => {
    if (display === 'name' && name && validates.isValidateName(name)) {
      setDisplay("email")
    }
    if (display === 'name' && !validates.isValidateName(name)) {
      setRequire(true)
    } else if (display === 'email' && !validates.isValidateEmail(email)) {
      setRequire(true)
    } else {
      if (name && email) {
        hanldeCreateLead();
        setDisplay("")
        if(nextstep) {
          nextstep()
        }
      }
      setRequire(false)
    }
  }

  return (
    <div className="w-full  flex justify-end">
      {display === "name" &&
        <FormInput validateText="Please enter the correct name" value={name} setValue={setName} type="text" label="Name" hanldeClick={hanldeClick} require={require} setRequire={setRequire} />
      }
      {display === "email" &&
        <FormInput validateText="Please enter the correct email" value={email} setValue={setEmail} type="email" label="Email" hanldeClick={hanldeClick} require={require} setRequire={setRequire} />
      }
    </div>
  );
}
