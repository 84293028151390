import { useApp } from "../../../AppContext";
import { formatTime } from "../../../libs";
import "../../tailwind.css";
const ListInputComponent = ({ inboxs }: any) => {
  const { setInbox, inboxId, setCurrentPage } = useApp();

  return (
    <div className="w-full h-full pb-1">
      <div className="w-full h-full overflow-y-auto py-3  px-1  text-black ">
        {inboxs?.map((item: any, idx: number) => (
          <div
            key={idx}
            onClick={() => {
              setInbox(item?._id);
              setCurrentPage('message')
            }}
            className="py-2 px-2 border-b-[1px] border-gray-300 cursor-pointer hover:bg-slate-100  "
          >
            <div className=" w-full flex justify-between">
              <p className="text-[14px] font-bold overflow-ellipsis whitespace-nowrap">
                {item?.conversationId?.type === "assistant"
                  ? "Bot"
                  : item?.conversationId?.type === "agent"
                  ? "Agent"
                  : (item?.leadId?.name || "User")}
              </p>
              <p className="text-[13px] text-[#5A6A9D]  min-w-[85px] pl-[4px]">
                {formatTime(item?.updatedAt)}
              </p>
            </div>
            <p className="line-clamp-2">{item?.conversationId.message}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
export default ListInputComponent;
