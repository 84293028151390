// Define a type for language codes
export type LanguageCode =
  | 'en'
  | 'ar'
  | 'de'
  | 'es'
  | 'fr'
  | 'hi'
  | 'it'
  | 'ja'
  | 'nl'
  | 'pt'
  | 'tr'
  | 'ur'
  | 'vi'
  | 'zh'
  | 'zh-CN'
  | 'zh-TW'
  | 'ms'
  | 'ru'  // Russian
  | 'ko'  // Korean
  | 'pl'  // Polish
  | 'sv'  // Swedish
  | 'no'  // Norwegian
  | 'da'  // Danish
  | 'fi'  // Finnish
  | 'th'  // Thai
  | 'he'  // Hebrew
  | 'id'  // Indonesian
  | 'el'  // Greek
  | 'cs'; // Czech;

// Define translations for each language
export const translations: Record<LanguageCode, Record<string, string>> = {
  en: {
    hello: "Hello 👋",
    hello_how_can_i_help: "Hello, How can I help you today?",
    channel: "Channel",
    contact_us_via_channel: "Contact us via channel",
    send_us_a_message: "Send us a message",
    type_a_message: "Type a message",
    find_for_help: "Find for help",
    privacy_policy: "Privacy policy",
    recent_messages: "Recent Messages",
  },
  ar: {
    hello: "مرحبا 👋",
    hello_how_can_i_help: "مرحبًا، كيف يمكنني مساعدتك اليوم؟",
    channel: "قناة",
    contact_us_via_channel: "اتصل بنا عبر القناة",
    send_us_a_message: "أرسل لنا رسالة",
    type_a_message: "اكتب رسالة",
    find_for_help: "ابحث عن المساعدة",
    privacy_policy: "سياسة الخصوصية",
    recent_messages: "الرسائل الأخيرة",
  },
  de: {
    hello: "Hallo 👋",
    hello_how_can_i_help: "Hallo, wie kann ich Ihnen heute helfen?",
    channel: "Kanal",
    contact_us_via_channel: "Kontaktieren Sie uns über den Kanal",
    send_us_a_message: "Senden Sie uns eine Nachricht",
    type_a_message: "Geben Sie eine Nachricht ein",
    find_for_help: "Hilfe finden",
    privacy_policy: "Datenschutz-Bestimmungen",
    recent_messages: "Aktuelle Nachrichten",
  },
  es: {
    hello: "Hola 👋",
    hello_how_can_i_help: "Hola, ¿cómo puedo ayudarte hoy?",
    channel: "Canal",
    contact_us_via_channel: "Contáctanos a través del canal",
    send_us_a_message: "Envíanos un mensaje",
    type_a_message: "Escribe un mensaje",
    find_for_help: "Buscar ayuda",
    privacy_policy: "Política de privacidad",
    recent_messages: "Mensajes recientes",
  },
  fr: {
    hello: "Bonjour 👋",
    hello_how_can_i_help: "Bonjour, comment puis-je vous aider aujourd'hui?",
    channel: "Canal",
    contact_us_via_channel: "Contactez-nous via le canal",
    send_us_a_message: "Envoyez-nous un message",
    type_a_message: "Tapez un message",
    find_for_help: "Rechercher de l'aide",
    privacy_policy: "Politique de confidentialité",
    recent_messages: "Messages récents",
  },
  hi: {
    hello: "नमस्ते 👋",
    hello_how_can_i_help: "नमस्ते, मैं आज आपकी कैसे मदद कर सकता हूँ?",
    channel: "चैनल",
    contact_us_via_channel: "चैनल के माध्यम से हमसे संपर्क करें",
    send_us_a_message: "हमें एक संदेश भेजें",
    type_a_message: "एक संदेश टाइप करें",
    find_for_help: "मदद के लिए खोजें",
    privacy_policy: "गोपनीयता नीति",
    recent_messages: "हाल के संदेश",
  },
  it: {
    hello: "Ciao 👋",
    hello_how_can_i_help: "Ciao, come posso aiutarti oggi?",
    channel: "Canale",
    contact_us_via_channel: "Contattaci tramite canale",
    send_us_a_message: "Inviaci un messaggio",
    type_a_message: "Scrivi un messaggio",
    find_for_help: "Trova aiuto",
    privacy_policy: "Politica sulla riservatezza",
    recent_messages: "Messaggi recenti",
  },
  ja: {
    hello: "こんにちは 👋",
    hello_how_can_i_help: "こんにちは、今日はどのようにお手伝いできますか？",
    channel: "チャンネル",
    contact_us_via_channel: "チャンネル経由でお問い合わせください",
    send_us_a_message: "メッセージを送ってください",
    type_a_message: "メッセージを入力してください",
    find_for_help: "ヘルプを探す",
    privacy_policy: "プライバシーポリシー",
    recent_messages: "最近のメッセージ",
  },
  nl: {
    hello: "Hallo 👋",
    hello_how_can_i_help: "Hallo, hoe kan ik je vandaag helpen?",
    channel: "Kanaal",
    contact_us_via_channel: "Neem contact met ons op via het kanaal",
    send_us_a_message: "Stuur ons een bericht",
    type_a_message: "Typ een bericht",
    find_for_help: "Zoek hulp",
    privacy_policy: "Privacybeleid",
    recent_messages: "Recente berichten",
  },
  pt: {
    hello: "Olá 👋",
    hello_how_can_i_help: "Olá, como posso ajudá-lo hoje?",
    channel: "Canal",
    contact_us_via_channel: "Entre em contato conosco via canal",
    send_us_a_message: "Envie-nos uma mensagem",
    type_a_message: "Digite uma mensagem",
    find_for_help: "Encontre ajuda",
    privacy_policy: "Política de Privacidade",
    recent_messages: "Mensagens recentes",
  },
  tr: {
    hello: "Merhaba 👋",
    hello_how_can_i_help: "Merhaba, size bugün nasıl yardımcı olabilirim?",
    channel: "Kanal",
    contact_us_via_channel: "Kanal üzerinden bizimle iletişime geçin",
    send_us_a_message: "Bize bir mesaj gönderin",
    type_a_message: "Bir mesaj yazın",
    find_for_help: "Yardım bul",
    privacy_policy: "Gizlilik Politikası",
    recent_messages: "Son Mesajlar",
  },
  ur: {
    hello: "ہیلو 👋",
    hello_how_can_i_help: "ہیلو، آج میں آپ کی کیسے مدد کر سکتا ہوں؟",
    channel: "چینل",
    contact_us_via_channel: "چینل کے ذریعے ہم سے رابطہ کریں",
    send_us_a_message: "ہمیں پیغام بھیجیں",
    type_a_message: "ایک پیغام ٹائپ کریں",
    find_for_help: "مدد کے لئے تلاش کریں",
    privacy_policy: "رازداری کی پالیسی",
    recent_messages: "حالیہ پیغامات",
  },
  vi: {
    hello: "Xin chào 👋",
    hello_how_can_i_help: "Xin chào, tôi có thể giúp gì cho bạn hôm nay?",
    channel: "Kênh",
    contact_us_via_channel: "Liên hệ với chúng tôi qua kênh",
    send_us_a_message: "Gửi cho chúng tôi một tin nhắn",
    type_a_message: "Nhập một tin nhắn",
    find_for_help: "Tìm kiếm sự giúp đỡ",
    privacy_policy: "Chính sách bảo mật",
    recent_messages: "Tin nhắn gần đây",
  },
  zh: {
    hello: "你好 👋",
    hello_how_can_i_help: "你好，今天我能帮你什么吗？",
    channel: "渠道",
    contact_us_via_channel: "通过渠道联系我们",
    send_us_a_message: "给我们发信息",
    type_a_message: "输入信息",
    find_for_help: "寻找帮助",
    privacy_policy: "隐私政策",
    recent_messages: "最近的消息",
  },
  'zh-CN': {
    hello: "你好 👋",
    hello_how_can_i_help: "你好，今天我能帮你什么吗？",
    channel: "渠道",
    contact_us_via_channel: "通过渠道联系我们",
    send_us_a_message: "给我们发信息",
    type_a_message: "输入信息",
    find_for_help: "寻找帮助",
    privacy_policy: "隐私政策",
    recent_messages: "最近的消息",
  },
  'zh-TW': {
    hello: "你好 👋",
    hello_how_can_i_help: "你好，今天我能幫你什麼嗎？",
    channel: "渠道",
    contact_us_via_channel: "通過渠道聯繫我們",
    send_us_a_message: "給我們發信息",
    type_a_message: "輸入信息",
    find_for_help: "尋找幫助",
    privacy_policy: "隱私政策",
    recent_messages: "最近的消息",
  },
  ms: {
    hello: "Halo 👋",
    hello_how_can_i_help: "Halo, bagaimana saya dapat membantu Anda hari ini?",
    channel: "Saluran",
    contact_us_via_channel: "Hubungi kami melalui saluran",
    send_us_a_message: "Kirimkan kami pesan",
    type_a_message: "Ketik pesan",
    find_for_help: "Cari bantuan",
    privacy_policy: "Kebijakan Privasi",
    recent_messages: "Pesan Terkini",
  },
  ru: {
    hello: "Здравствуйте 👋",
    hello_how_can_i_help: "Здравствуйте, чем я могу вам помочь сегодня?",
    channel: "Канал",
    contact_us_via_channel: "Свяжитесь с нами через канал",
    send_us_a_message: "Отправьте нам сообщение",
    type_a_message: "Напишите сообщение",
    find_for_help: "Найти помощь",
    privacy_policy: "Политика конфиденциальности",
    recent_messages: "Последние сообщения",
  },
  ko: {
    hello: "안녕하세요 👋",
    hello_how_can_i_help: "안녕하세요, 오늘 무엇을 도와드릴까요?",
    channel: "채널",
    contact_us_via_channel: "채널을 통해 문의하십시오",
    send_us_a_message: "메시지를 보내주세요",
    type_a_message: "메시지를 입력하세요",
    find_for_help: "도움 찾기",
    privacy_policy: "개인정보 보호정책",
    recent_messages: "최근 메시지",
  },
  pl: {
    hello: "Cześć 👋",
    hello_how_can_i_help: "Cześć, jak mogę ci dzisiaj pomóc?",
    channel: "Kanał",
    contact_us_via_channel: "Skontaktuj się z nami przez kanał",
    send_us_a_message: "Wyślij nam wiadomość",
    type_a_message: "Napisz wiadomość",
    find_for_help: "Znajdź pomoc",
    privacy_policy: "Polityka prywatności",
    recent_messages: "Ostatnie wiadomości",
  },
  sv: {
    hello: "Hej 👋",
    hello_how_can_i_help: "Hej, hur kan jag hjälpa dig idag?",
    channel: "Kanal",
    contact_us_via_channel: "Kontakta oss via kanal",
    send_us_a_message: "Skicka oss ett meddelande",
    type_a_message: "Skriv ett meddelande",
    find_for_help: "Hitta hjälp",
    privacy_policy: "Integritetspolicy",
    recent_messages: "Senaste meddelanden",
  },
  no: {
    hello: "Hei 👋",
    hello_how_can_i_help: "Hei, hvordan kan jeg hjelpe deg i dag?",
    channel: "Kanal",
    contact_us_via_channel: "Kontakt oss via kanal",
    send_us_a_message: "Send oss en melding",
    type_a_message: "Skriv en melding",
    find_for_help: "Finn hjelp",
    privacy_policy: "Personvernregler",
    recent_messages: "Nylige meldinger",
  },
  da: {
    hello: "Hej 👋",
    hello_how_can_i_help: "Hej, hvordan kan jeg hjælpe dig i dag?",
    channel: "Kanal",
    contact_us_via_channel: "Kontakt os via kanal",
    send_us_a_message: "Send os en besked",
    type_a_message: "Skriv en besked",
    find_for_help: "Find hjælp",
    privacy_policy: "Fortrolighedspolitik",
    recent_messages: "Seneste beskeder",
  },
  fi: {
    hello: "Hei 👋",
    hello_how_can_i_help: "Hei, kuinka voin auttaa sinua tänään?",
    channel: "Kanava",
    contact_us_via_channel: "Ota meihin yhteyttä kanavan kautta",
    send_us_a_message: "Lähetä meille viesti",
    type_a_message: "Kirjoita viesti",
    find_for_help: "Etsi apua",
    privacy_policy: "Tietosuojakäytäntö",
    recent_messages: "Viimeisimmät viestit",
  },
  th: {
    hello: "สวัสดี 👋",
    hello_how_can_i_help: "สวัสดี, วันนี้ฉันสามารถช่วยคุณได้อย่างไร?",
    channel: "ช่อง",
    contact_us_via_channel: "ติดต่อเราผ่านช่องทาง",
    send_us_a_message: "ส่งข้อความถึงเรา",
    type_a_message: "พิมพ์ข้อความ",
    find_for_help: "ค้นหาความช่วยเหลือ",
    privacy_policy: "นโยบายความเป็นส่วนตัว",
    recent_messages: "ข้อความล่าสุด",
  },
  he: {
    hello: "שלום 👋",
    hello_how_can_i_help: "שלום, איך אני יכול לעזור לך היום?",
    channel: "ערוץ",
    contact_us_via_channel: "צור איתנו קשר דרך ערוץ",
    send_us_a_message: "שלח לנו הודעה",
    type_a_message: "הקלד הודעה",
    find_for_help: "מצא עזרה",
    privacy_policy: "מדיניות פרטיות",
    recent_messages: "הודעות אחרונות",
  },
  id: {
    hello: "Halo 👋",
    hello_how_can_i_help: "Halo, bagaimana saya bisa membantu Anda hari ini?",
    channel: "Saluran",
    contact_us_via_channel: "Hubungi kami melalui saluran",
    send_us_a_message: "Kirimkan kami pesan",
    type_a_message: "Ketik pesan",
    find_for_help: "Temukan bantuan",
    privacy_policy: "Kebijakan Privasi",
    recent_messages: "Pesan Terbaru",
  },
  el: {
    hello: "Γεια 👋",
    hello_how_can_i_help: "Γεια, πώς μπορώ να σας βοηθήσω σήμερα;",
    channel: "Κανάλι",
    contact_us_via_channel: "Επικοινωνήστε μαζί μας μέσω καναλιού",
    send_us_a_message: "Στείλτε μας ένα μήνυμα",
    type_a_message: "Πληκτρολογήστε ένα μήνυμα",
    find_for_help: "Βρείτε βοήθεια",
    privacy_policy: "Πολιτική Απορρήτου",
    recent_messages: "Πρόσφατα μηνύματα",
  },
  cs: {
    hello: "Ahoj 👋",
    hello_how_can_i_help: "Ahoj, jak vám dnes mohu pomoci?",
    channel: "Kanál",
    contact_us_via_channel: "Kontaktujte nás prostřednictvím kanálu",
    send_us_a_message: "Pošlete nám zprávu",
    type_a_message: "Napište zprávu",
    find_for_help: "Najít pomoc",
    privacy_policy: "Zásady ochrany osobních údajů",
    recent_messages: "Poslední zprávy",
  }
};
