import "../../tailwind.css";
import { ReactComponent as ChevronForwardIcon } from '../../icons/chevronforward.svg';
const FlowComponent = ({
  chatbot,
  setFlow,
  setNewChat,
  setPrompt,
  setMessages,
}) => {
  return (
    <div className="w-full h-auto max-h-full cursor-pointer bg-white  text-[#272727] py-3 px-4 rounded-[20px]">
      <div
        id="stylescroll"
        className="w-full h-full max-h-[200px] max-md:max-h-[220px] overflow-y-auto "
      >
        <p className="font-semibold text-[18px] text-[#272727]">Menu</p>
        {chatbot &&
          chatbot?.flows.length > 0 &&
          chatbot?.flows[0].options.map((item, idx) => (
            <div
              onClick={() => {
                setFlow(item);
                setPrompt(undefined);
                setNewChat(true);
                setMessages([]);
              }}
              key={idx}
              className="text-[#272727] flex justify-between gap-3 items-center pr-3 "
            >
              <p className="text-[#272727] font-medium text-[16px] py-2 ">
                {item.name}
              </p>
              <ChevronForwardIcon className="w-[18px] text-gray-500"/>
            </div>
          ))}
      </div>
    </div>
  );
};
export default FlowComponent;
