export default function FormEmail({
    type,
    value,
    setValue,
    validateText,
    hanldeClick,
    label,
    require,
    setRequire,
}: any) {

    return (
        <div className="w-[100%] h-full rounded-lg border-[1px] border-blue-300 p-3">
            <p className=" font-medium">{label}</p>
            <div className="relative">
                <input type={type || "text"} onChange={(e) => setValue(e.target.value)} onFocus={() => setRequire(false)} onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        hanldeClick();
                    }
                }} value={value} className="w-full text-[12px] outline-none h-[30px] border-[1px] border-gray-300 rounded-md pl-3 pr-[50px]"></input>
                <div onClick={hanldeClick} className="absolute right-0 top-0 text-white bottom-0 w-[40px] h-[30px] bg-blue-600 rounded-r-md cursor-pointer flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                        <path d="M9 6l6 6-6 6" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </div>
            {require &&
                <p className="text-blue-500 text-[12px]">{validateText}</p>}
        </div>
    );
}
