import { useState, useRef } from "react";
import ChatAvatar from "./chat-avatar";
import Markdown from "react-markdown";
import { useEffect } from "react";
import ChatItem from "./chat-item";
import ChatLead from "./chat-lead";
import { Button } from "../element/button";
import {
  generateString,
  convertArrayToString,
  replaceName,
} from "../../../libs";
import { useApp } from '../../../AppContext';
const renderers = {
  a: (props) => {
    let { href, children } = props;
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  },
};

export const Flow = ({
  dataflow,
  isFull,
  messages,
  setMessages,
  closeMess,
  setCloseMess,
  mess,
  append,
  reload,
  dataBot,
  setFlow,
  flow,
  uuid,
  messResult,
  setMessResult,
  lead,
  handleCreateMess,
  setIsAgent,
  sendAgent,
  isAgent,
  newFlow,
}) => {
  const [data, setData] = useState();
  const { translations } = useApp();
  const scrollableChatContainerRef = useRef(null);
  useEffect(() => {
    const dataIndex0 = dataflow.find((item) => item.index === 0);
    setData(dataIndex0);
  }, [dataflow]);
  useEffect(() => {
    if (scrollableChatContainerRef) {
      scrollableChatContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    if (closeMess) {
      setMessResult([]);
      setCloseMess(false);
      setFlow();
    }
  }, [closeMess]);
  const hanldeClick = (flow) => {
    if (isAgent) {
      sendMessIsAgent(flow?.name);
    } else {
      hanldeGetNextstep(flow);
    }
  };
  useEffect(() => {
    if (newFlow) {
      if (newFlow.type == "button") {
        hanldeGetNextstep(newFlow);
      } else if (newFlow.type == "button:link") {
        window.open(newFlow.data[0], "_blank");
      } else if (newFlow.type == "button:phone") {
        window.open(`tel:${newFlow.data[0]}`, "_blank");
      }
    }
  }, [newFlow]);

  const hanldeGetNextstep = (flow) => {
    if (flow.next_step) {
      const nextstep = dataflow.find((item) => item._id === flow.next_step);
      if (nextstep) {
        if (nextstep.options && nextstep.options.length > 0) {
          nextstep.options.find((item) => {
            if (item.type === "mail") {
              sendAgent(dataBot._id);
            }
          });
        }
        if (nextstep.isPrompt) {
          setMessResult((result) => [...result, nextstep.message]);
          setFlow(nextstep);
          const Array = mess.filter(
            (item) => item.content && item.content !== ""
          );
          const Message = [
            // { content: flow.name, id: generateString(8), role: "user" },
            { content: nextstep.message, id: generateString(8), role: "user" },
          ];

          Array.push(...Message);
          setMessages(Array);
          reload();
          handleCreateMess(true, nextstep.message, "user");
        } else if (nextstep.message) {
          setMessResult((result) => [...result, flow.name]);
          setFlow(nextstep);
          const Array = mess.filter(
            (item) => item.content && item.content !== ""
          );
          const Message = [
            { content: flow.name, id: generateString(8), role: "user" },
            {
              content: nextstep.message,
              id: generateString(8),
              role: "assistant",
            },
            // { content: "", id: generateString(8), role: "user" },
          ];

          Array.push(...Message);
          setMessages(Array);
          handleCreateMess(true, nextstep.message, "user");
          // reload();
        } else {
          setFlow(nextstep);
          const Array = mess.filter(
            (item) => item.content && item.content !== ""
          );
          Array.push({
            role: "user",
            content: convertArrayToString([...messResult, flow.name]),
            id: generateString(10),
          });
          setMessages(Array);
          reload();
          setMessResult([]);
          // setFlow();
          handleCreateMess(
            true,
            convertArrayToString([...messResult, flow.name]),
            "user"
          );
        }
      }
    } else {
      const Array = mess.filter((item) => item.content && item.content !== "");
      Array.push({
        role: "user",
        content: flow.name,
        id: generateString(8),
      });
      setMessages(Array);
      setMessResult([]);
      setFlow();
      reload();
      handleCreateMess(true, flow.name, "user");
    }
  };

  const sendMessIsAgent = (name) => {
    const Array = mess.filter((item) => item.content && item.content !== "");
    Array.push({
      role: "user",
      content: name,
      id: generateString(8),
    });
    setMessages(Array);
    handleCreateMess(true, name, "user");
  };
  const sendMessAgent = (flow) => {
    const Array = mess.filter((item) => item.content && item.content !== "");
    const nextstep = dataflow.find((item) => item._id === flow.next_step);
    if (flow?.next_step) {
      Array.push(
        {
          role: "user",
          content: flow?.name,
          id: generateString(8),
        },
        {
          role: "assistant",
          content:
            nextstep?.message ||
            "Please wait a few minutes to receive a response from our support staff",
          id: generateString(8),
        }
      );
    } else {
      Array.push(
        {
          role: "user",
          content: flow?.name,
          id: generateString(8),
        },
        {
          role: "assistant",
          content:
            "Please wait a few minutes to receive a response from our support staff",
          id: generateString(8),
        }
      );
    }
    setMessages(Array);
    setIsAgent(true);
    sendAgent(dataBot?._id);
    handleCreateMess(true, flow?.name, "user");
  };

  return (
    <div id="stylescroll" className="h-full overflow-auto">
      <div className="flex items-start gap-4 pt-5 ">
        <ChatAvatar />
        <Markdown components={renderers}>
          {replaceName(
            dataBot?.welcomeMessage ?? (`${translations?.hello_how_can_i_help}`
              || "Hello @name, 👋 I'm your AI Assistant. How can I help you?"),
            lead?.name || ""
          )}
        </Markdown>
      </div>

      {!newFlow &&
        data?.options &&
        data?.options.length > 0 &&
        data?.options.map((item, idx) => (
          <div
            key={idx}
            // onSubmit={handleSubmit}
            style={{
              justifyContent: isFull ? "start" : "end",
              paddingRight: "10px",
            }}
            className="flex items-start gap-4 pt-5  "
          >
            {item?.type === "button" && (
              <Button onClick={() => hanldeClick(item)} label={item?.name} />
            )}
            {item.type == "button:link" && (
              <a href={item.data[0] ? item.data[0] : ""} target="_blank">
                <Button label={item?.name} />
              </a>
            )}
            {item.type == "button:phone" && (
              <a href={item.data[0] ? `tel:${item.data[0]}` : ""}>
                <Button label={item?.name} />
              </a>
            )}
            {item.type == "lead" && (
              <ChatLead
                uuid={uuid}
                botId={dataBot._id}
                nextstep={() => hanldeClick(item)}
              />
            )}
            {item?.type === "agent" && (
              <Button onClick={() => sendMessAgent(item)} label={item?.name} />
            )}
          </div>
        ))}
      <div className={`flex flex-col gap-4`}>
        {messages.map(
          (m) =>
            m.content && (
              <ChatItem
                key={m.id}
                message={m}
                mess={mess}
                append={append}
                setMessages={setMessages}
                reload={reload}
                dataBot={dataBot}
              />
            )
        )}
      </div>
      <div className="flex gap-4 justify-center flex-wrap pt-5">
        {newFlow.type == "lead" && (
          <ChatLead
            uuid={uuid}
            botId={dataBot._id}
            nextstep={() => hanldeClick(newFlow)}
          />
        )}
        {flow?.options &&
          flow?.options?.length > 0 &&
          flow?.options.map((item, idx) => {
            return (
              <div key={idx} className="flex items-start gap-4  justify-center">
                {item.type == "button" && (
                  <Button
                    onClick={() => hanldeClick(item)}
                    label={item?.name}
                  />
                )}
                {item.type == "button:link" && (
                  <a href={item.data[0] ? item.data[0] : ""} target="_blank">
                    <Button label={item?.name} />
                  </a>
                )}
                {item.type == "button:phone" && (
                  <a href={item.data[0] ? `tel:${item.data[0]}` : ""}>
                    <Button label={item?.name} />
                  </a>
                )}

                {item.type == "lead" && (
                  <ChatLead
                    uuid={uuid}
                    botId={dataBot._id}
                    nextstep={() => hanldeClick(item)}
                  />
                )}
              </div>
            );
          })}
      </div>
      <div ref={scrollableChatContainerRef} />
    </div>
  );
};
