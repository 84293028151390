import "./tailwind.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { accessToken, isAppDomain, appURL } from "../libs";
import InitComponent from "./ui/init/InitComponent";
import ChatNew from "./ui/newchat/ChatNewComponent";
import { useApp } from "../AppContext";
import { ReactComponent as ChatIcon } from "./icons/chat.svg";
import { ReactComponent as SendIcon } from "./icons/send.svg";
import { ReactComponent as ChevronBackIcon } from "./icons/chevronback.svg";
import { ReactComponent as ChatbubblesIcon } from "./icons/chatbubbles.svg";
import { isMobile } from "react-device-detect";

export default function ChatBotInit({
  dataBot,
  botId,
  dataflow,
  uuid,
  lead,
  setLead,
  setShow,
  start
}) {
  const [closeMess, setCloseMess] = useState(false);
  const [messages, setMessages] = useState();
  const [newChat, setNewChat] = useState(true);
  const [prompt, setPrompt] = useState();
  const [flow, setFlow] = useState();
  const [checkContinue, setContinue] = useState(false);
  const [loading, setLoading] = useState(false);
  const { translations, chatbot, setInbox, inboxId, setCurrentPage, page } = useApp();
  useEffect(() => {
    HanldeGetDataMess();
    setCurrentPage(start)
  }, [])
  const HanldeGetDataMess = async () => {
    setInbox(undefined);
    setContinue(true);
    setLoading(true);
    if (uuid && botId) {
      await axios
        .get(`${appURL}/api/inbox?botId=${botId}&uuid=${uuid}`)
        .then((res) => {
          const sortdata = res.data.sort(
            (a, b) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
          setMessages(sortdata);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const hanldeClickBack = () => {
    if (page == 'message') {
      setCurrentPage('messages')
    } else {
      setCurrentPage(start || 'home')
    }
    setInbox(undefined);
  };

  const isFull = isAppDomain || isMobile;
  const color = chatbot?.chatbotColor ? chatbot?.chatbotColor : "#3047EC";
  return (
    <div
      style={{
        width: !isMobile && "390px",
        height: !isMobile && "570px",
        zIndex: 999999,
        background: page == 'home' ? `linear-gradient(to bottom, ${color} 2%, #FFFFFF 57%)` : '',
        backdropFilter: "blur(10px)",
      }}
      className={`relative max-w-5xl mx-auto ${isAppDomain ? "w-screen h-full" : isMobile ? "w-full h-full" : " "
        }    overflow-hidden bg-white shadow rounded-lg text-[14px]`}
    >
      <div className={`w-full h-[10%] flex justify-between items-center px-4 relative`} style={{
        backgroundColor: page == 'home' ? "" : color,
      }}>
        {(page != 'home' && page != 'init') ? (
          <div className="text-[18px] font-bold flex items-center gap-2 w-full relative">
            <div
              onClick={hanldeClickBack}
              className="cursor-pointer"
            >
              <ChevronBackIcon className="w-[22px] text-white" />
            </div>
            <p className="text-white">{page == 'messages' ? "Messages" : dataBot?.name ?? "TaggoAI"}</p>
          </div>
        ) : (
          <div className="flex gap-3 items-center justify-between w-full">
            <div className="flex gap-3 items-center">
              <ChatIcon
                style={{ color: "#fff" }}
                className="w-[32px] h-[32px]"
              />
              <div className="text-[18px] text-white font-bold">
                <p>{dataBot?.name ?? "TaggoAI"}</p>
              </div>
            </div>
            {page == 'init' && <ChatbubblesIcon
              style={{ color: "#fff" }}
              className="w-[24px]"
              onClick={() => setCurrentPage('messages')}
            />}

          </div>
        )}
      </div>

      {(page != 'home') && <div className={`w-full h-[90%] ${page == 'messages' && ""}`}>
        <ChatNew
          chatbot={dataBot}
          botId={botId}
          dataBot={dataBot}
          closeMess={closeMess}
          setCloseMess={setCloseMess}
          uuid={uuid}
          lead={lead}
          setLead={setLead}
          // sendAgent={sendAgent}
          messagesold={messages}
          dataflow={dataflow}
          prompt={prompt}
          flow={flow}
          checkContinue={checkContinue}
          loading={loading}
          setPrompt={setPrompt}
          setFlow={setFlow}
        />
      </div>}

      {
        (page == 'home') &&
        <div className="w-full h-[90%] p-[10px] overflow-y-auto scrollable-style relative pb-[20px]">
          <InitComponent
            chatbot={dataBot}
            setNewChat={setNewChat}
            setPrompt={setPrompt}
            setFlow={setFlow}
            HanldeGetDataMess={HanldeGetDataMess}
            setMessages={setMessages}
            messages={messages}
          />
        </div>
      }

      {(page === 'home' || page == 'messages') && (
        <div className="absolute bottom-2 left-0 w-full  flex justify-center items-center py-2">
          <button
            onClick={() => {
              setCurrentPage('chat');
              setPrompt(undefined);
              setFlow(undefined);
              // setMessages([]);
              setInbox("");
            }}
            style={{
              backgroundColor: chatbot?.chatbotColor || "#3047EC",
            }}
            className={`px-3 h-10 text-white font-bold  rounded-[22px] flex gap-3 items-center`}
          >
            <span>{translations?.send_us_a_message}</span>
            <SendIcon className="w-[24px] text-white cursor-pointer" />
          </button>
        </div>
      )}
    </div>
  );
}
