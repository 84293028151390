import "../../tailwind.css";
// import Chat from "../components/Chat";
import Chat from "../../../components/Chat";

const ChatNew = ({
  chatbot,
  botId,
  closeMess,
  setCloseMess,
  uuid,
  setLead,
  messages,
  messagesold,
  lead,
  dataflow,
  prompt,
  flow,
  checkContinue,
  loading,
}) => {
  return (
    <div className="w-full h-full  text-[#303437] ">
      <Chat
        botId={botId}
        dataBot={chatbot}
        closeMess={closeMess}
        setCloseMess={setCloseMess}
        uuid={uuid}
        lead={lead}
        setLead={setLead}
        // sendAgent={sendAgent}
        messagesold={messagesold}
        dataflow={dataflow}
        newchat
        prompt={prompt}
        newFlow={flow}
        checkContinue={checkContinue}
        loading={loading}
      />
    </div>
  );
};
export default ChatNew;
